.main {
  width: 100vw;
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  padding: 120px 24px 160px 24px;

  &:before {
    background: radial-gradient(circle, rgba(2, 0, 36, 0) 0, var(--gray1) 100%);
    position: absolute;
    content: '';
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
  }

  &:after {
    content: '';
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    opacity: 0.2;
    filter: invert(1);

    @media (prefers-color-scheme: dark) {
      filter: unset;
    }
  }

  h1 {
    font-size: 32px;
    color: var(--gray12);
    font-weight: 600;
    letter-spacing: -2px;
    line-height: 40px;
  }

  p {
    color: var(--gray11);
    margin-top: 8px;
    font-size: 16px;
  }
}

.content {
  height: fit-content;
  position: relative;
  z-index: 3;
  width: 100%;
  max-width: 640px;

  &:after {
    background-image: radial-gradient(at 27% 37%, hsla(215, 98%, 61%, 1) 0px, transparent 50%),
    radial-gradient(at 97% 21%, hsla(256, 98%, 72%, 1) 0px, transparent 50%),
    radial-gradient(at 52% 99%, hsla(354, 98%, 61%, 1) 0px, transparent 50%),
    radial-gradient(at 10% 29%, hsla(133, 96%, 67%, 1) 0px, transparent 50%),
    radial-gradient(at 97% 96%, hsla(38, 60%, 74%, 1) 0px, transparent 50%),
    radial-gradient(at 33% 50%, hsla(222, 67%, 73%, 1) 0px, transparent 50%),
    radial-gradient(at 79% 53%, hsla(343, 68%, 79%, 1) 0px, transparent 50%);
    position: absolute;
    content: '';
    z-index: 2;
    width: 100%;
    height: 100%;
    filter: blur(100px) saturate(150%);
    z-index: -1;
    top: 80px;
    opacity: 0.2;
    transform: translateZ(0);

    @media (prefers-color-scheme: dark) {
      opacity: 0.1;
    }
  }
}

.meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;
  flex-wrap: wrap;
  gap: 16px;
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 12px;
  transform: translateY(12px);
}

.githubButton,
.installButton,
.switcher button {
  height: 40px;
  color: var(--gray12);
  border-radius: 9999px;
  font-size: 14px;
  transition-duration: 150ms;
  transition-property: background, color, transform;
  transition-timing-function: ease-in;
  will-change: transform;
}

.githubButton {
  width: 177px;
  padding: 0 12px;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;

  &:hover {
    background: var(--grayA3);
  }

  &:active {
    background: var(--grayA5);
    transform: scale(0.97);
  }

  &:focus-visible {
    outline: 0;
    outline: 2px solid var(--gray7);
  }
}

.installButton {
  background: var(--grayA3);
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 0px 8px 0 16px;
  cursor: copy;
  font-weight: 500;

  &:hover {
    background: var(--grayA4);

    span {
      background: var(--grayA5);

      svg {
        color: var(--gray12);
      }
    }
  }

  &:focus-visible {
    outline: 0;
    outline: 2px solid var(--gray7);
    outline-offset: 2px;
  }

  &:active {
    background: var(--gray5);
    transform: scale(0.97);
  }

  span {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    background: var(--grayA3);
    border-radius: 9999px;
    transition: background 150ms ease;

    svg {
      size: 16px;
      color: var(--gray11);
      transition: color 150ms ease;
    }
  }
}

.switcher {
  display: grid;
  grid-template-columns: repeat(4, 100px);
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin-top: 48px;
  position: relative;

  button {
    height: 32px;
    line-height: 32px;
    display: flex;
    align-items: center;
    margin: auto;
    gap: 8px;
    padding: 0 16px;
    border-radius: 9999px;
    color: var(--gray11);
    font-size: 14px;
    cursor: pointer;
    user-select: none;
    position: relative;
    text-transform: capitalize;

    &:hover {
      color: var(--gray12);
    }

    &:active {
      transform: scale(0.96);
    }

    &:focus-visible {
      outline: 0;
      outline: 2px solid var(--gray7);
    }

    svg {
      width: 14px;
      height: 14px;
    }

    &[data-selected='true'] {
      color: var(--gray12);

      &:hover .activeTheme {
        background: var(--grayA6);
      }

      &:active {
        transform: scale(0.96);

        .activeTheme {
          background: var(--grayA7);
        }
      }
    }
  }

  .activeTheme {
    background: var(--grayA5);
    border-radius: 9999px;
    height: 32px;
    width: 100%;
    top: 0;
    position: absolute;
    left: 0;
  }

  .arrow {
    color: var(--gray11);
    user-select: none;
    position: absolute;
  }
}

.header {
  position: absolute;
  left: 0;
  top: -64px;
  gap: 8px;
  background: var(--gray3);
  padding: 4px;
  display: flex;
  align-items: center;
  border-radius: 9999px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    padding: 4px;
    border-radius: 9999px;
    color: var(--gray11);

    svg {
      width: 16px;
      height: 16px;
    }

    &[aria-selected='true'] {
      background: #ffffff;
      color: var(--gray12);
      box-shadow: 0px 2px 5px -2px rgb(0 0 0 / 15%), 0 1px 3px -1px rgb(0 0 0 / 20%);
    }
  }
}

.versionBadge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--grayA11);
  background: var(--grayA3);
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 8px;

  @media (prefers-color-scheme: dark) {
    background: var(--grayA2);
  }
}

.codeBlock {
  margin-top: 72px;
  position: relative;
}

.footer {
  display: flex;
  align-items: center;
  gap: 4px;
  width: fit-content;
  margin: 32px auto;
  bottom: 16px;
  color: var(--gray11);
  font-size: 13px;
  z-index: 3;
  position: absolute;
  bottom: 0;

  a {
    display: inline-flex;
    align-items: center;
    gap: 4px;
    color: var(--gray12);
    font-weight: 500;
    border-radius: 9999px;
    padding: 4px;
    margin: 0 -2px;
    transition: background 150ms ease;

    &:hover,
    &:focus-visible {
      background: var(--grayA4);
      outline: 0;
    }
  }

  img {
    width: 20px;
    height: 20px;
    border: 1px solid var(--gray5);
    border-radius: 9999px;
  }
}

.line {
  height: 20px;
  width: 180px;
  margin: 64px auto;
  filter: invert(1);
  mask-image: linear-gradient(90deg, transparent, #fff 4rem, #fff calc(100% - 4rem), transparent);

  @media (prefers-color-scheme: dark) {
    filter: unset;
  }
}

.line2 {
  height: 1px;
  width: 300px;
  background: var(--gray7);
  position: absolute;
  top: 0;
  mask-image: linear-gradient(90deg, transparent, #fff 4rem, #fff calc(100% - 4rem), transparent);
}

.line3 {
  height: 300px;
  width: calc(100% + 32px);
  position: absolute;
  top: -16px;
  left: -16px;

  border-radius: 16px 16px 0 0;
  --size: 1px;
  --gradient: linear-gradient(to top, var(--gray1), var(--gray7));

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: inherit;
    padding: var(--size);
    background: linear-gradient(to top, var(--gray1), var(--gray7));
    mask: linear-gradient(black, black) content-box, linear-gradient(black, black);
    mask-composite: exclude;
    transform: translateZ(0);

    @media (prefers-color-scheme: dark) {
      mask: none;
      mask-composite: none;
      opacity: 0.2;
      backdrop-filter: blur(20px);
    }
  }
}

.raunoSignature,
.pacoSignature {
  position: absolute;
  height: fit-content;
  color: var(--gray11);
  pointer-events: none;
}

.raunoSignature {
  width: 120px;
  stroke-dashoffset: 1;
  stroke-dasharray: 1;
  right: -48px;
}

.pacoSignature {
  width: 120px;
  stroke-dashoffset: 1;
  stroke-dasharray: 1;
  left: -8px;
}

.footerText {
  display: flex;
  display: flex;
  align-items: center;
  gap: 4px;
  opacity: 0;
}

.footer[data-animate='true'] {
  .raunoSignature path {
    animation: drawRaunoSignature 1.5s ease forwards 0.5s;
  }

  .pacoSignature path {
    animation: drawPacoSignature 0.8s linear forwards 0.5s;
  }

  .footerText {
    animation: showFooter 1s linear forwards 3s;
  }
}

@keyframes drawPacoSignature {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes drawRaunoSignature {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes showFooter {
  100% {
    opacity: 1;
  }
}

@media (max-width: 640px) {
  .main {
    padding-top: 24px;
    padding-bottom: 120px;
  }

  .switcher {
    grid-template-columns: repeat(2, 100px);
    gap: 16px;

    .arrow {
      display: none;
    }
  }
}


:root {
  --font-sans: 'Inter', --apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
  Droid Sans, Helvetica Neue, sans-serif;
  --app-bg: var(--gray1);
  --cmdk-shadow: 0 16px 70px rgb(0 0 0 / 20%);

  --lowContrast: #ffffff;
  --highContrast: #000000;

  --gray1: hsl(0, 0%, 99%);
  --gray2: hsl(0, 0%, 97.3%);
  --gray3: hsl(0, 0%, 95.1%);
  --gray4: hsl(0, 0%, 93%);
  --gray5: hsl(0, 0%, 90.9%);
  --gray6: hsl(0, 0%, 88.7%);
  --gray7: hsl(0, 0%, 85.8%);
  --gray8: hsl(0, 0%, 78%);
  --gray9: hsl(0, 0%, 56.1%);
  --gray10: hsl(0, 0%, 52.3%);
  --gray11: hsl(0, 0%, 43.5%);
  --gray12: hsl(0, 0%, 9%);

  --grayA1: hsla(0, 0%, 0%, 0.012);
  --grayA2: hsla(0, 0%, 0%, 0.027);
  --grayA3: hsla(0, 0%, 0%, 0.047);
  --grayA4: hsla(0, 0%, 0%, 0.071);
  --grayA5: hsla(0, 0%, 0%, 0.09);
  --grayA6: hsla(0, 0%, 0%, 0.114);
  --grayA7: hsla(0, 0%, 0%, 0.141);
  --grayA8: hsla(0, 0%, 0%, 0.22);
  --grayA9: hsla(0, 0%, 0%, 0.439);
  --grayA10: hsla(0, 0%, 0%, 0.478);
  --grayA11: hsla(0, 0%, 0%, 0.565);
  --grayA12: hsla(0, 0%, 0%, 0.91);

  --blue1: hsl(206, 100%, 99.2%);
  --blue2: hsl(210, 100%, 98%);
  --blue3: hsl(209, 100%, 96.5%);
  --blue4: hsl(210, 98.8%, 94%);
  --blue5: hsl(209, 95%, 90.1%);
  --blue6: hsl(209, 81.2%, 84.5%);
  --blue7: hsl(208, 77.5%, 76.9%);
  --blue8: hsl(206, 81.9%, 65.3%);
  --blue9: hsl(206, 100%, 50%);
  --blue10: hsl(208, 100%, 47.3%);
  --blue11: hsl(211, 100%, 43.2%);
  --blue12: hsl(211, 100%, 15%);
}

.dark {
  --app-bg: var(--gray1);

  --lowContrast: #000000;
  --highContrast: #ffffff;

  --gray1: hsl(0, 0%, 8.5%);
  --gray2: hsl(0, 0%, 11%);
  --gray3: hsl(0, 0%, 13.6%);
  --gray4: hsl(0, 0%, 15.8%);
  --gray5: hsl(0, 0%, 17.9%);
  --gray6: hsl(0, 0%, 20.5%);
  --gray7: hsl(0, 0%, 24.3%);
  --gray8: hsl(0, 0%, 31.2%);
  --gray9: hsl(0, 0%, 43.9%);
  --gray10: hsl(0, 0%, 49.4%);
  --gray11: hsl(0, 0%, 62.8%);
  --gray12: hsl(0, 0%, 93%);

  --grayA1: hsla(0, 0%, 100%, 0);
  --grayA2: hsla(0, 0%, 100%, 0.026);
  --grayA3: hsla(0, 0%, 100%, 0.056);
  --grayA4: hsla(0, 0%, 100%, 0.077);
  --grayA5: hsla(0, 0%, 100%, 0.103);
  --grayA6: hsla(0, 0%, 100%, 0.129);
  --grayA7: hsla(0, 0%, 100%, 0.172);
  --grayA8: hsla(0, 0%, 100%, 0.249);
  --grayA9: hsla(0, 0%, 100%, 0.386);
  --grayA10: hsla(0, 0%, 100%, 0.446);
  --grayA11: hsla(0, 0%, 100%, 0.592);
  --grayA12: hsla(0, 0%, 100%, 0.923);

  --blue1: hsl(212, 35%, 9.2%);
  --blue2: hsl(216, 50%, 11.8%);
  --blue3: hsl(214, 59.4%, 15.3%);
  --blue4: hsl(214, 65.8%, 17.9%);
  --blue5: hsl(213, 71.2%, 20.2%);
  --blue6: hsl(212, 77.4%, 23.1%);
  --blue7: hsl(211, 85.1%, 27.4%);
  --blue8: hsl(211, 89.7%, 34.1%);
  --blue9: hsl(206, 100%, 50%);
  --blue10: hsl(209, 100%, 60.6%);
  --blue11: hsl(210, 100%, 66.1%);
  --blue12: hsl(206, 98%, 95.8%);
}


.raycast {
  [cmdk-root] {

    border-radius: 12px;
    padding: 16px;
    backdrop-filter: blur(10px);
    border: 1px solid var(--gray6);
    position: relative;
    line-height: 16px;
    background: var(--lowContrast);
    white-space: pre-wrap;
    box-shadow: rgb(0 0 0 / 10%) 0px 5px 30px -5px;

    max-width: 640px;
    width: 100%;
    background: var(--gray1);
    border-radius: 12px;
    padding: 8px 0;
    font-family: var(--font-sans);
    box-shadow: var(--cmdk-shadow);
    border: 1px solid var(--gray6);

    outline: none;

    .dark & {
      background: var(--gray2);
      border: 0;

      &:after {
        content: '';
        background: linear-gradient(
          to right,
          var(--gray6) 20%,
          var(--gray6) 40%,
          var(--gray10) 50%,
          var(--gray10) 55%,
          var(--gray6) 70%,
          var(--gray6) 100%
        );
        z-index: -1;
        position: absolute;
        border-radius: 12px;
        top: -1px;
        left: -1px;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        animation: shine 3s ease forwards 0.1s;
        background-size: 200% auto;
      }

      &:before {
        content: '';
        z-index: -1;
        position: absolute;
        border-radius: 12px;
        top: -1px;
        left: -1px;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        box-shadow: 0 0 0 1px transparent;
        animation: border 1s linear forwards 0.5s;
      }
    }

    kbd {
      font-family: var(--font-sans);
      background: var(--gray5);
      color: var(--gray11);
      height: 20px;
      width: 20px;
      border-radius: 4px;
      padding: 0 4px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:first-of-type {
        margin-left: 8px;
      }
    }
  }

  [cmdk-input] {
    font-family: var(--font-sans);
    border: none;
    width: 100%;
    font-size: 15px;
    padding: 8px 16px;
    outline: none;
    background: var(--bg);
    color: var(--gray12);

    &::placeholder {
      color: var(--gray9);
    }
  }

  [cmdk-raycast-top-shine] {
    .dark & {
      background: linear-gradient(
        90deg,
        rgba(56, 189, 248, 0),
        var(--gray5) 20%,
        var(--gray9) 67.19%,
        rgba(236, 72, 153, 0)
      );
      height: 1px;
      position: absolute;
      top: -1px;
      width: 100%;
      z-index: -1;
      opacity: 0;
      animation: showTopShine 0.1s ease forwards 0.2s;
    }
  }

  [cmdk-raycast-loader] {
    --loader-color: var(--gray9);
    border: 0;
    width: 100%;
    width: 100%;
    left: 0;
    height: 1px;
    background: var(--gray6);
    position: relative;
    overflow: visible;
    display: block;
    margin-top: 12px;
    margin-bottom: 12px;

    &:after {
      content: '';
      width: 50%;
      height: 1px;
      position: absolute;
      background: linear-gradient(90deg, transparent 0%, var(--loader-color) 50%, transparent 100%);
      top: -1px;
      opacity: 0;
      animation-duration: 1.5s;
      animation-delay: 1s;
      animation-timing-function: ease;
      animation-name: loading;
    }
  }

  [cmdk-item] {
    content-visibility: auto;

    cursor: pointer;
    height: 40px;
    border-radius: 8px;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0 8px;
    color: var(--gray12);
    user-select: none;
    will-change: background, color;
    transition: all 150ms ease;
    transition-property: none;

    &[data-selected='true'] {
      background: var(--gray4);
      color: var(--gray12);
    }

    &[data-disabled='true'] {
      color: var(--gray8);
      cursor: not-allowed;
    }

    &:active {
      transition-property: background;
      background: var(--gray4);
    }

    &:first-child {
      margin-top: 8px;
    }

    & + [cmdk-item] {
      margin-top: 4px;
    }

    svg {
      width: 18px;
      height: 18px;
    }
  }

  [cmdk-raycast-meta] {
    margin-left: auto;
    color: var(--gray11);
    font-size: 13px;
  }

  [cmdk-list] {
    padding: 0 8px;
    height: 393px;
    overflow: auto;
    overscroll-behavior: contain;
    scroll-padding-block-end: 40px;
    transition: 100ms ease;
    transition-property: height;
    padding-bottom: 40px;
  }

  [cmdk-raycast-open-trigger],
  [cmdk-raycast-subcommand-trigger] {
    color: var(--gray11);
    justify-self: flex-end;
    padding: 0px 4px 0px 8px;
    border-radius: 6px;
    font-weight: 500;
    font-size: 12px;
    height: 28px;
    letter-spacing: -0.25px;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
  }


  [cmdk-raycast-clipboard-icon],
  [cmdk-raycast-hammer-icon] {
    width: 20px;
    height: 20px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;

    svg {
      width: 14px;
      height: 14px;
    }
  }

  [cmdk-raycast-clipboard-icon] {
    background: linear-gradient(to bottom, #f55354, #eb4646);
  }

  [cmdk-raycast-hammer-icon] {
    background: linear-gradient(to bottom, #6cb9a3, #2c6459);
  }

  [cmdk-raycast-open-trigger] {
    display: flex;
    align-items: center;
    color: var(--gray12);
  }

  [cmdk-raycast-subcommand-trigger] {
    display: flex;
    align-items: center;
    gap: 4px;
    right: 8px;
    bottom: 8px;

    svg {
      width: 14px;
      height: 14px;
    }

    hr {
      height: 100%;
      background: var(--gray6);
      border: 0;
      width: 1px;
    }

    &[aria-expanded='true'],
    &:hover {
      background: var(--gray4);

      kbd {
        background: var(--gray7);
      }
    }
  }

  [cmdk-separator] {
    height: 1px;
    width: 100%;
    background: var(--gray5);
    margin: 4px 0;
  }

  *:not([hidden]) + [cmdk-group] {
    margin-top: 8px;
  }

  [cmdk-group-heading] {
    user-select: none;
    font-size: 12px;
    color: var(--gray11);
    padding: 0 8px;
    display: flex;
    align-items: center;
  }

  [cmdk-raycast-footer] {
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: absolute;
    background: var(--gray1);
    bottom: 0;
    padding: 8px;
    border-top: 1px solid var(--gray6);
    border-radius: 0 0 12px 12px;

    svg {
      width: 20px;
      height: 20px;
      filter: grayscale(1);
      margin-right: auto;
    }

    hr {
      height: 12px;
      width: 1px;
      border: 0;
      background: var(--gray6);
      margin: 0 4px 0px 12px;
    }

    @media (prefers-color-scheme: dark) {
      background: var(--gray2);
    }
  }

  [cmdk-dialog] {
    z-index: var(--layer-portal);
    position: fixed;
    left: 50%;
    top: var(--page-top);
    transform: translateX(-50%);

    [cmdk] {
      width: 640px;
      transform-origin: center center;
      animation: dialogIn var(--transition-fast) forwards;
    }

    &[data-state='closed'] [cmdk] {
      animation: dialogOut var(--transition-fast) forwards;
    }
  }

  [cmdk-empty] {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    white-space: pre-wrap;
    color: var(--gray11);
  }
}

@keyframes loading {
  0% {
    opacity: 0;
    transform: translateX(0);
  }

  50% {
    opacity: 1;
    transform: translateX(100%);
  }

  100% {
    opacity: 0;
    transform: translateX(0);
  }
}

@keyframes shine {
  to {
    background-position: 200% center;
    opacity: 0;
  }
}

@keyframes border {
  to {
    box-shadow: 0 0 0 1px var(--gray6);
  }
}

@keyframes showTopShine {
  to {
    opacity: 1;
  }
}

.raycast-submenu {
  [cmdk-root] {
    display: flex;
    flex-direction: column;
    width: 320px;
    border: 1px solid var(--gray6);
    background: var(--gray2);
    border-radius: 8px;
  }

  [cmdk-list] {
    padding: 8px;
    overflow: auto;
    overscroll-behavior: contain;
    transition: 100ms ease;
    transition-property: height;
  }

  [cmdk-item] {
    height: 40px;

    cursor: pointer;
    height: 40px;
    border-radius: 8px;
    font-size: 13px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0 8px;
    color: var(--gray12);
    user-select: none;
    will-change: background, color;
    transition: all 150ms ease;
    transition-property: none;

    &[aria-selected='true'] {
      background: var(--gray5);
      color: var(--gray12);

      [cmdk-raycast-submenu-shortcuts] kbd {
        background: var(--gray7);
      }
    }

    &[aria-disabled='true'] {
      color: var(--gray8);
      cursor: not-allowed;
    }

    svg {
      width: 16px;
      height: 16px;
    }

    [cmdk-raycast-submenu-shortcuts] {
      display: flex;
      margin-left: auto;
      gap: 2px;

      kbd {
        font-family: var(--font-sans);
        background: var(--gray5);
        color: var(--gray11);
        height: 20px;
        width: 20px;
        border-radius: 4px;
        padding: 0 4px;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:first-of-type {
          margin-left: 8px;
        }
      }
    }
  }

  [cmdk-group-heading] {
    text-transform: capitalize;
    font-size: 12px;
    color: var(--gray11);
    font-weight: 500;
    margin-bottom: 8px;
    margin-top: 8px;
    margin-left: 4px;
  }

  [cmdk-input] {
    padding: 12px;
    font-family: var(--font-sans);
    border: 0;
    border-top: 1px solid var(--gray6);
    font-size: 13px;
    background: transparent;
    margin-top: auto;
    width: 100%;
    outline: 0;
    border-radius: 0;
  }

  animation-duration: 0.2s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  transform-origin: var(--radix-popover-content-transform-origin);

  &[data-state='open'] {
    animation-name: slideIn;
  }

  &[data-state='closed'] {
    animation-name: slideOut;
  }

  [cmdk-empty] {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    white-space: pre-wrap;
    font-size: 14px;
    color: var(--gray11);
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: scale(0.96);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes slideOut {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(0.96);
  }
}

@media (max-width: 640px) {
  .raycast {
    [cmdk-input] {
      font-size: 16px;
    }
  }
}
